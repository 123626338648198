<template>
  <div class="settings_group">
    <div class="title_small">Impersonate a user</div>

    <div class="settings_list">
      <div class="settings_item">
        <div class="settings_item_opener">
          <i class="atuicons atuicons-information"></i>Enter Username<span
            class="settings_right"
            ><i class="atuicons atuicons-arrowright"></i
          ></span>
        </div>
        <div class="settings_item_content">
          <form @submit.prevent="handleSumbmit()">
            <div class="settings_inputs">
              <div class="settings__input">
                <label class="settings_input_title required"
                  >Enter username</label
                ><input
                  v-model="form.username"
                  class="settings_input"
                  type="text"
                />
              </div>
            </div>
            <div class="settings_save">
              <SaveButton
                :isLoading="isLoading"
                text="Switch User"
              ></SaveButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SaveButton from "@/components/SaveButton";
import { setImpersonate, getImpersonate } from "@/utils/impersonate";

export default {
  name: "Impersonate",
  components: { SaveButton },
  data() {
    return {
      isLoading: false,
      form: {
        username: getImpersonate(),
      },
    };
  },
  methods: {
    handleSumbmit() {
      this.isLoading = true;
      setTimeout(() => {
        setImpersonate(this.form.username);
        location.reload();
      }, 1000);
    },
  },
};
</script>