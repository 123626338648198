<template>
  <div class="buysubscription" id="tandc">
    <div class="buys_center">
      <div class="buys">
        <div class="buys_head">
          <div class="buys_close"><i class="atuicons atuicons-close"></i></div>
        </div>
        <div class="termsandconditions">
          <h2>Terms and Conditions</h2>
          <span v-html="terms"></span>
          <div class="termsandconditions_buttons">
            <a
              v-if="!isLoading"
              class="button button_red"
              href="#"
              @click.prevent="accept"
              >Accept</a
            ><a
              v-if="!isLoading"
              class="tandcDecOpener button button_grey"
              href="#"
              @click.prevent="decline"
              >Decline</a
            >
            <a v-if="isLoading" class="button button_grey">Please wait...</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import $ from "jquery";
import NProgress from "nprogress";

export default {
  name: "Term",
  data() {
    return {
      isLoading: false,
      terms: "",
    };
  },
  mounted: function () {
    $("#tandc").fadeIn(200);
    this.terms = this.$store.getters.profile.terms;
  },
  methods: {
    accept() {
      this.isLoading = true;

      NProgress.start();
      this.$store
        .dispatch("user/acceptTerms")
        .then(() => {
          this.$router.push({ name: "Today" }).catch((error) => {
            console.log(
              "router push error in accepting tnc success handler",
              error
            );
          });
        })
        .catch(() => {
          console.log("error while accepting terms");
        })
        .finally(() => {
          NProgress.done();
          this.isLoading = false;
        });
    },
    decline() {
      swal({
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: "Warning!",
        content: {
          element: "div",
          attributes: {
            innerHTML:
              "Please contact customer support at <strong><a href='mailto:info@atumobile.com'>info@atumobile.com</a></strong> regarding your subscription.",
          },
        },
        icon: "warning",
        buttons: ["Back", "Logout"],
        dangerMode: true,
      }).then((willLogout) => {
        if (willLogout) {
          document.getElementsByClassName("header_logout_a")[0].click();
        }
      });
    },
  },
};
</script>
