<template>
  <div class="settings_group">
    <div class="title_small">Security</div>

    <div class="settings_list">
      <div class="settings_item">
        <div class="settings_item_opener">
          <i class="atuicons atuicons-information"></i>Change Password<span
            class="settings_right"
            ><i class="atuicons atuicons-arrowright"></i
          ></span>
        </div>
        <div class="settings_item_content">
          <form @submit.prevent="handleSumbmit()">
            <div class="settings_inputs">
              <div class="settings__input">
                <label class="settings_input_title required"
                  >Enter Current Password</label
                ><input
                  v-model="form.oldPassword"
                  class="settings_input"
                  type="password"
                  required=""
                />
              </div>
              <div class="settings__input">
                <label class="settings_input_title required"
                  >Enter New Password</label
                ><input
                  v-model="form.newPassword"
                  class="settings_input"
                  type="password"
                  required=""
                />
              </div>
            </div>
            <div class="settings_save">
              <SaveButton :isLoading="isLoading"></SaveButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SaveButton from "@/components/SaveButton";
import swal from "sweetalert";

export default {
  name: "ChangePassword",
  components: { SaveButton },
  data() {
    return {
      isLoading: false,
      form: {
        oldPassword: "",
        newPassword: "",
      },
    };
  },
  methods: {
    resetForm() {
      this.form.oldPassword = "";
      this.form.newPassword = "";
    },
    handleSumbmit() {
      this.isLoading = true;
      this.$store
        .dispatch("user/changePassword", this.form)
        .then((response) => {
          this.resetForm();

          swal(
            "Password Changed!",
            response.message || "Your password have been updated successfully",
            "success"
          );
        })
        .catch(() => {
          console.log("error while changing password");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>