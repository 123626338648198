<template>
  <div class="settings_group">
    <div class="title_small">Profile</div>

    <div class="settings_list">
      <div class="settings_item">
        <div class="settings_item_opener">
          <i class="atuicons atuicons-information"></i>Your Information<span
            class="settings_right"
            ><i class="atuicons atuicons-arrowright"></i
          ></span>
        </div>
        <div class="settings_item_content">
          <form @submit.prevent="handleSumbmit()">
            <div class="settings_inputs">
              <div class="settings__input">
                <label class="settings_input_title required">First Name</label
                ><input
                  v-model="form.firstname"
                  class="settings_input"
                  type="text"
                  required=""
                />
              </div>
              <div class="settings__input">
                <label class="settings_input_title">Last Name</label
                ><input
                  v-model="form.lastname"
                  class="settings_input"
                  type="text"
                />
              </div>
              <div class="settings__input fullwidth">
                <label class="settings_input_title">Email Address</label
                ><input
                  class="settings_input"
                  type="email"
                  required=""
                  disabled=""
                  :value="profile.email"
                />
              </div>
            </div>
            <div class="settings_save">
              <SaveButton :isLoading="isLoading"></SaveButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
import SaveButton from "@/components/SaveButton";

export default {
  name: "ProfileSetting",
  data() {
    return {
      isLoading: false,
      form: {
        firstname: "",
        lastname: "",
      },
    };
  },
  components: { SaveButton },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  mounted() {
    this.form.firstname = this.profile.firstname;
    this.form.lastname = this.profile.lastname;
  },
  methods: {
    handleSumbmit() {
      this.isLoading = true;
      this.$store
        .dispatch("user/updateInfo", this.form)
        .then((response) => {
          swal(
            "Profile Updated!",
            response.message || "Your profile have been updated successfully",
            "success"
          );
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>