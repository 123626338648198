<template>
  <div class="settings_group">
    <div class="title_small">Support</div>
    <div class="settings_list">
      <div class="settings_item">
        <div class="settings_item_opener">
          <i class="atuicons atuicons-support"></i>Technical Support<span
            class="settings_right"
            ><i class="atuicons atuicons-arrowright"></i
          ></span>
        </div>
        <div class="settings_item_content">
          <form>
            <div class="settings_inputs">
              <div class="settings__input">
                <label class="title_small">Contact</label>
                <div class="settings_input_link">
                  <a href="mailto:info@atumobile.com">info@atumobile.com</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="settings_item">
        <div class="settings_item_opener">
          <i class="atuicons atuicons-support"></i>Frequently Asked
          Questions<span class="settings_right"
            ><i class="atuicons atuicons-arrowright"></i
          ></span>
        </div>
        <div class="settings_item_content">
          <form>
            <div class="settings_inputs">
              <div class="settings__input">
                <label class="title_small">FAQ</label>
                <div class="settings_input_link">
                  <router-link to="/manual/index">
                    View All Frequently Asked Questions
                  </router-link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Support",
};
</script>
