<template>
  <Fragment>
    <main class="maincontent">
      <div class="mainh1">
        <div class="wrapper">
          <div class="mainh1_flex">
            <h1>Your Account</h1>
          </div>
        </div>
      </div>
      <div v-if="isExecutingSubscription" class="yourplan">
        <div class="wrapper">
          <a href="#">Please wait...</a>
        </div>
      </div>
      <section v-if="!isExecutingSubscription" class="settings_section">
        <div class="wrapper">
          <div class="settings">
            <Impersonate v-if="profile.isAdmin"></Impersonate>
            <ProfileSetting></ProfileSetting>
            <ChangePassword></ChangePassword>
            <PurchaseHistoryList
              @buyClickCountUpdated="buyClickCount++"
            ></PurchaseHistoryList>
            <WorkoutStartDateSetting
              v-if="profile.isPremiumMember"
            ></WorkoutStartDateSetting>
            <Timezone></Timezone>
            <VideoResolution></VideoResolution>
            <Support></Support>
          </div>
        </div>
      </section>
    </main>
    <PaymentPopup :buyClickCount="buyClickCount"></PaymentPopup>
    <Term
      v-if="profile.isPremiumMember && !profile.hasAcceptedTermsConditions"
    ></Term>
  </Fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import custom_js from "./custom";
import swal from "sweetalert";
import NProgress from "nprogress";
import {
  ProfileSetting,
  ChangePassword,
  Support,
  PaymentPopup,
  PurchaseHistoryList,
  WorkoutStartDateSetting,
  Term,
  Impersonate,
  Timezone,
  VideoResolution,
} from "./components";

export default {
  name: "Account",
  components: {
    Fragment,
    ProfileSetting,
    ChangePassword,
    Support,
    PaymentPopup,
    PurchaseHistoryList,
    WorkoutStartDateSetting,
    Term,
    Impersonate,
    Timezone,
    VideoResolution,
  },
  data() {
    return {
      isExecutingSubscription: false,
      buyClickCount: 0,
    };
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  created() {
    this.executeSubscription();
  },
  mounted: function () {
    if (this.$route.query.buy && "true" === this.$route.query.buy) {
      document.getElementById("buysubscription").click();
    }
    this.customjs();
  },
  methods: {
    customjs() {
      custom_js();
    },
    executeSubscription() {
    let originalUrl = window.location.href;
    let decodedUrl = decodeURIComponent(originalUrl);
    let fixedUrl = decodedUrl.replace(/\?/g, '&').replace(/#/g, '&').replace(/&+/g, '&').replace(/&$/, '');
    let urlParams = new URLSearchParams(fixedUrl);
    const subscription_id = urlParams.get('subscription_id');
    const ba_token = urlParams.get('ba_token');
    const success = urlParams.get('success');

      if (!ba_token) {
        console.log("no ba_token found in the url", urlParams);
        console.log("originalUrl", originalUrl);
        console.log("fixedUrl", fixedUrl);
        console.log("urlParams", urlParams);
        return;
      }

       if (!subscription_id) {
        console.log("no subscription_id found in the url", urlParams);
        console.log("originalUrl", originalUrl);
        console.log("fixedUrl", fixedUrl);
        console.log("urlParams", urlParams);
        return;
      }

      if (!success) {
        console.log("no success found in the url", urlParams);
        console.log("originalUrl", originalUrl);
        console.log("fixedUrl", fixedUrl);
        console.log("urlParams", urlParams);
        return;
      }

      if (success != "true") {
        swal("Payment Declined!", "You have declined the payment.", "warning");
        this.$router.push("/account/index");
        return;
      }

      this.isExecutingSubscription = true;

      NProgress.start();
      this.$store
        .dispatch("user/executeSubscription", { baToken: ba_token, agreementId: subscription_id })
        .then((response) => {
          swal(
            "Subscription Activated!",
            response.message || "Your subscription has been activated!",
            "success"
          );
          this.$router.push({ name: "Today" }).catch((error) => {
            console.log(
              "router push error in executing paypal agreement success handler",
              error
            );
          });
        })
        .catch(() => {
          console.log("could not execute agreement");
        })
        .finally(() => {
          NProgress.done();
          this.isExecutingSubscription = false;
        });
    },
  },
};
</script>

<style scoped>
@import "~@/assets/css/plans.css";
@import "~@/assets/css/settings.css";
</style>
