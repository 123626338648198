import request from '@/utils/request'

export function fetchPlans() {
    return request({
        url: `/api/plan/index`,
        method: 'get',
    })
}


