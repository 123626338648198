<template>
  <div class="settings_group">
    <div class="title_small">Video Resolution</div>
    <div class="settings_list">
      <div class="settings_item">
        <div class="settings_item_opener">
          <i class="atuicons atuicons-video-setting"></i>Video Resolution
          <span class="settings_right"
            ><i class="atuicons atuicons-arrowright"></i
          ></span>
        </div>
        <div class="settings_item_content">
          <form @submit.prevent="handleSumbmit()">
            <div class="settings_inputs">
              <div class="settings__input fullwidth">
                <select class="settings_select" v-model="form.resolution">
                  <option
                    v-for="resolution in resolutions"
                    :key="resolution.value"
                    :value="resolution.value"
                  >
                    {{ resolution.key }}
                  </option>
                </select>
              </div>
            </div>
            <div class="settings_save">
              <SaveButton :isLoading="isLoading"></SaveButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaveButton from "@/components/SaveButton";
import NProgress from "nprogress";
import swal from "sweetalert";

export default {
  name: "VideoResolution",
  components: { SaveButton },
  mounted() {
    this.form.resolution = this.profile.properties.video_resolution
      ? this.profile.properties.video_resolution
      : this.form.resolution;
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  methods: {
    handleSumbmit() {
      NProgress.start();
      this.isLoading = true;
      this.$store
        .dispatch("user/updateVideoQuality", {
          videoResolution: this.form.resolution,
        })
        .then((response) => {
          swal(
            "Success!",
            response.message ||
              "Your video quality settings have been updated successfully",
            "success"
          );
        })
        .catch(() => {
          console.log("error while updating video quality");
        })
        .finally(() => {
          NProgress.done();
          this.isLoading = false;
        });
    },
  },
  data() {
    return {
      isLoading: false,
      resolutions: [
        {
          key: "High (recommended for users with faster internet)",
          value: "high",
        },
        {
          key: "Low (recommended for users with slower internet)",
          value: "low",
        },
      ],
      form: {
        resolution: "high",
      },
    };
  },
};
</script>
