<template>
  <div class="settings_group">
    <div class="title_small">Time Zone</div>
    <div class="settings_list">
      <div class="settings_item">
        <div class="settings_item_opener">
          <i class="atuicons atuicons-clock"></i>Your Time Zone<span
            class="settings_right"
            ><i class="atuicons atuicons-arrowright"></i
          ></span>
        </div>
        <div class="settings_item_content">
          <form @submit.prevent="handleSumbmit()">
            <div class="settings_inputs">
              <div class="settings__input fullwidth">
                <label class="settings_input_title">Choose your Time Zone</label
                ><select class="settings_select" v-model="form.timezone">
                  <option
                    v-for="timezone in timezones"
                    :key="timezone.value"
                    :value="timezone.value"
                  >
                    {{ timezone.key }}
                  </option>
                </select>
              </div>
            </div>
            <div class="settings_save">
              <SaveButton :isLoading="isLoading"></SaveButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaveButton from "@/components/SaveButton";
import { getTimezoneList } from "@/api/settings";
import NProgress from "nprogress";
import swal from "sweetalert";

export default {
  name: "Timezone",
  components: { SaveButton },
  created() {
    this.loadTimezoneList();
    this.form.timezone = this.profile.timezone
      ? this.profile.timezone
      : this.form.timezone;
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  methods: {
    handleSumbmit() {
      NProgress.start();
      this.isLoading = true;
      this.$store
        .dispatch("user/updateTimezone", {
          timezone: this.form.timezone,
        })
        .then((response) => {
          swal(
            "Timezone updated!",
            response.message || "Your timezone have been updated successfully",
            "success"
          );
        })
        .catch(() => {
          console.log("error while timezone");
        })
        .finally(() => {
          NProgress.done();
          this.isLoading = false;
        });
    },
    loadTimezoneList() {
      this.isLoading = true;
      getTimezoneList()
        .then(({ data }) => {
          this.timezones = data;
        })
        .catch((error) => {
          console.log("could not fetch timezone list", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  data() {
    return {
      isLoading: false,
      timezones: [],
      form: {
        timezone: "",
      },
    };
  },
};
</script>
