<template>
  <Fragment>
    <div class="settings__input">
      <label class="settings_input_title">Date</label
      ><input
        class="settings_input"
        type="text"
        disabled
        :value="payment.created | moment('MMMM Do, YYYY')"
      />
    </div>
    <div class="settings__input">
      <label class="settings_input_title">Payment #</label
      ><input
        class="settings_input"
        type="text"
        disabled
        :value="payment.transactionId"
      />
    </div>
    <div class="settings__input">
      <label class="settings_input_title">Paypal or Credit Card</label
      ><input class="settings_input" type="text" disabled :value="method" />
    </div>
    <div class="settings__input">
      <label class="settings_input_title">Plan</label
      ><input
        class="settings_input"
        type="text"
        disabled
        :value="payment.planName"
      />
    </div>
    <div class="settings__input">
      <label class="settings_input_title">Amount</label
      ><input
        class="settings_input"
        type="text"
        disabled
        :value="'$' + payment.amount"
      />
    </div>
    <div v-if="!isLast" class="settings__input fullwidth"></div>
  </Fragment>
</template>


<script>
import { Fragment } from "vue-fragment";

export default {
  name: "PurchaseItem",
  props: { payment: Object, isLast: Boolean },
  components: { Fragment },
  computed: {
    method() {
      return this.payment.method.toLowerCase() == "elavon"
        ? "Credit Card"
        : "PayPal";
    },
  },
};
</script>

<style scoped>
input[disabled] {
  cursor: not-allowed;
}
</style>



