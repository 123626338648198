<template>
  <div class="settings_group">
    <div class="title_small">Workout Start Date</div>
    <div class="settings_list">
      <div class="settings_item">
        <div class="settings_item_opener">
          <i class="atuicons atuicons-weightunit"></i>Workout Start Date<span
            class="settings_right"
            ><i class="atuicons atuicons-arrowright"></i
          ></span>
        </div>
        <div class="settings_item_content">
          <form @submit.prevent="handleSubmit">
            <div class="settings_inputs">
              <div class="settings__input fullwidth">
                <label class="settings_input_title">Start Date</label>
                <DatePicker
                  input-class="settings_input DatePicker"
                  v-model="form.workoutStartDate"
                ></DatePicker>
              </div>
            </div>
            <div class="settings_save">
              <SaveButton
                :isLoading="isLoading"
                format="MMM dd, yyyy"
                required="required"
              ></SaveButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import DatePicker from "vuejs-datepicker";

import SaveButton from "@/components/SaveButton";

export default {
  name: "WorkoutStartDateSetting",
  components: { DatePicker, SaveButton },
  data() {
    return {
      isLoading: false,
      form: {
        workoutStartDate: 0,
      },
    };
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  mounted() {
    let timestamp = parseInt(this.profile.workoutStartDate);
    if (timestamp > 0) {
      this.form.workoutStartDate = new Date(timestamp * 1000);
    }
  },
  methods: {
    handleSubmit() {
      swal({
        title: "Warning!",
        text: "The new workout start date will immediately reset your workout schedule and your workout log. This change cannot be undone.",
        icon: "warning",
        buttons: ["Cancel", "Proceed"],
        dangerMode: true,
      }).then((willProceed) => {
        if (willProceed) {
          const { workoutStartDate } = this.form;
          let date = `${workoutStartDate.getFullYear()}-${
            workoutStartDate.getMonth() + 1
          }-${workoutStartDate.getDate()}`;

          this.isLoading = true;
          this.$store
            .dispatch("user/changeWorkoutStartDate", { workoutStartDate: date })
            .then(() => {
              swal(
                "Workout Start Date Changed!",
                "Your workout start date has been updated successfully",
                "success"
              );
            })
            .catch((error) => {
              console.log(error.message);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>
