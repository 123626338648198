<template>
  <div class="subscription_buttons">
    <p v-if="profile.isPremiumMember">
      Your membership will
      {{
        profile.status.toLowerCase() == "cancelled" ? "expire" : "auto-renew"
      }}
      on:
      {{ profile.membershipExpiryDate | moment("MMMM Do, YYYY") }}
    </p>

    <a
      style="cursor: default !important"
      v-if="
        profile.status.toLowerCase() == 'cancelled' &&
        profile.isPremiumMember &&
        !profile.isTrial
      "
      >SUBSCRIPTION CANCELLED</a
    >

    <a
      style="cursor: default !important"
      v-if="profile.isTrial && profile.isPremiumMember"
      > {{ profile.isSponsored ? `${profile.trialDays} day sponsored membership` : `${profile.trialDays} day free trial` }}</a
    >

    <a
      v-if="
        profile.isPremiumMember && profile.status.toLowerCase() == 'activated'
      "
      @click.prevent="cancelSubscription"
      href="#"
      id="canselsubscription"
      >{{ isLoading ? "Please wait..." : "CANCEL SUBSCRIPTION" }}</a
    >

    <a
      v-if="!profile.isPremiumMember"
      href="#"
      id="buysubscription"
      @click.prevent="onBuySubscription"
    >
      <span>BUY SUBSCRIPTION</span>
    </a>
  </div>
</template>
<script>
import swal from "sweetalert";
import NProgress from "nprogress";
export default {
  name: "Subscription",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    onBuySubscription() {
      if (this.$route.query.buy) {
        this.$router
          .replace({
            query: {},
          })
          .catch((error) => {
            console.log("router replace error onBuySubscription", error);
          });
      }
      this.$emit("buyClick", true);
    },
    cancelSubscription() {
      if (this.isLoading) {
        return;
      }
      swal({
        title: "are you sure you wish to cancel?",
        text: "",
        icon: "warning",
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: "Cancel",
            value: true,
            visible: true
          },
          keep: {
            text: "Keep My Subscription",
            value: false,
            visible: true,
            className: "swal-button--danger"
          }
        },
        dangerMode: true,
      }).then((willProceed) => {
        if (willProceed) {
          this.isLoading = true;
          NProgress.start();
          this.$store
            .dispatch("user/cancelSubscription")
            .then((response) => {
              swal(
                "Subscription Cancelled!",
                response.message || "Your subscription has been cancelled!",
                "success"
              );
            })
            .finally(() => {
              this.isLoading = false;
              NProgress.done();
            });
        }
      });
    },
  },

  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>
