<template>
  <div class="settings_group">
    <div class="title_small">Your Membership</div>
    <div class="settings_list">
      <div class="settings_item">
        <div class="settings_item_opener">
          <i class="atuicons atuicons-payment"></i>Your Membership<span
            class="settings_right"
            ><i class="atuicons atuicons-arrowright"></i
          ></span>
        </div>
        <div class="settings_item_content">
          <div v-if="!profile.isTrial && payments.length < 1">
            No Payment Found!
          </div>
          <form v-else>
            <div class="settings_inputs">
              <PurchaseItem
                v-for="(payment, index) in payments"
                :key="payment.id"
                :payment="payment"
                :isLast="index == payments.length - 1"
              ></PurchaseItem>
            </div>
          </form>
          <Subscription @buyClick="onBuyClick"></Subscription>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseItem from "./PurchaseHistoryList/PurchaseItem";
import { fetchPaymentList } from "@/api/payment";
import { Subscription } from "../components";
export default {
  name: "PurchaseHistoryList",
  components: { PurchaseItem, Subscription },
  data() {
    return {
      payments: [],
    };
  },
  methods: {
    onBuyClick() {
      this.$emit("buyClickCountUpdated", true);
    },
    fetchPurchaseHistory() {
      fetchPaymentList()
        .then(({ data }) => {
          this.payments = data;
        })
        .catch(() => {
          console.log("error while fetching payment list");
        });
    },
  },
  created() {
    this.fetchPurchaseHistory();
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>
