import $ from "jquery";

export default function () {
    $(".maincontent").on("click", ".settings_item_opener", function (e) {
        e.preventDefault();
        $(this).toggleClass("opened");
        $(this).closest(".settings_item").find(".settings_item_content").slideToggle(200);
    });
    $(".buys_checkbox input").on("change", function () {
        if ($(this).prop('checked')) {
            $(".buys").addClass("redstyles");
        } else {
            $(".buys").removeClass("redstyles");
        }
    });

    $(".selectplanpopup").on("click", ".preorder_plan", function (e) {
        e.preventDefault();
        $(".selectplanpopup").hide();
        $(".confirmsubscription").show();
    });

    $(".buys_head, .confirm_head, .ClosePopup").on("click", function (e) {
        e.preventDefault();
        $(".buysubscription, .typepayment, .selectplanpopup, .creditcardpopup, .confirmsubscription").hide();
        $("body").removeClass("modalopen")
    });

    $(document).on("click", ".ClosePopup", function (e) {
        e.preventDefault();
        $(".buysubscription, .typepayment, .selectplanpopup, .creditcardpopup, .confirmsubscription").hide();
        $("body").removeClass("modalopen")
    });

    $(".confirmsubscription").on("click", ".ConfirmPlanBuy", function (e) {
        e.preventDefault();
        $(".confirmsubscription").hide();
        $(".typepayment").fadeIn(200);
    });

    $(".confirmsubscription").on("click", ".CancelPlan", function (e) {
        e.preventDefault();
        $(".confirmsubscription").hide();
        $(".selectplanpopup").fadeIn(200);
    });

    $(".creditcardpopup").on("click", ".CancelPay", function (e) {
        e.preventDefault();
        $(".creditcardpopup").hide();
    });

    $(".CreditCard").on("click", function (e) {
        e.preventDefault();
        $(".typepayment").hide();
        $(".creditcardpopup").show();
        $("body").addClass("modalopen")
    });
}